<template>
  <div class="article_box_xq" ref="navs">
    <share class="share"/>
    <h1 ref="h12">{{h1}}</h1>
    <div class="tabs" v-if="show_tab" ref="tabs">
       <nav v-for="(_,i) in tabListdata" :key="i" @click="tabclicks(_,i)">{{_.title}}<i></i></nav>
    </div>
    <nav v-html="htmltext" ref="" v-show="show" class="navs_"></nav>
  </div>
  <div class="nav_list" v-if="show_">
      <ul>
        <li v-for="(item,i) in htmltext" :key="i" @click="pushArticle_xq(item)">
          <img :src="item.is_top==1?
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':
         item.is_new==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR':
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618259YxAm28'" 
         :class="item.is_top==1||item.is_new==1?'top_top_new':'top_no_topNew'">
          <span>{{item.title}}</span>
          <a>{{item.created_at?item.created_at.slice(0,10):''}}</a>
        </li>
      </ul>
      <!-- 底部分页,-->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
    </div>
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import { get_article_list,get_aeticle_xq,getNavlist_id_f } from '@/api/API.js'
import { ref,onMounted,watch, nextTick, reactive,onUnmounted } from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
import usedownloadAttachments from '@/hooks/usedownloadAttachments.js'
import share from '@/components/share.vue'
let htmltext = ref('')
const route = useRoute()
const router = useRouter()
const baseUrl = process.env.VUE_APP_BASE_URL
let navs = ref(null)
let h1 = ref('')
let routeData = JSON.parse(base64_.decode(route.params.obj))
const show = ref(true)
const show_= ref(false)
const show_tab = ref(false)
const from = reactive({
  total:0,
  page:1,
  limit:10,
  type_id:routeData.article_id
})
let tabListdata = ref([])
// 获取文章详情或文章列表
let get_Navaeticlexq = async (Id) => {
  const {data:res} = await get_article_list(Id)
  if(res.code == 200 && navs.value){
    h1.value = routeData.title3 ? routeData.title3 : routeData.title2 ? routeData.title2 : routeData.title1
    navs.value.style.opacity = 1
    navs.value.style.margin = "0"
    navs.value.style['margin-left'] = 30/128+'rem'
    if(res.data.total == 1) {
      htmltext.value = res.data.data[0].content + usedownloadAttachments(res.data.data[0].pic_url)
      show_.value = false
      show.value = true
    } else{
      show_.value = true
      show.value = false
      htmltext.value = res.data.data
      from.total = res.data.total
    }
  }
}
// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
  const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
    // sessStor?from.page = sessStor:from.page = 1
    if(sessStor){
      from.page = sessStor
    }else{
      from.page = 1
    }
    from.type_id = routeData.article_id
    get_Navaeticlexq(from)
}

onMounted(() => {
  // 监听路由变化就根据传过来的导航id获取对应文章数据+动画效果
  watch(() => route.params.obj, () => {
    if(!route.params.obj) return
      route.params.obj?routeData = JSON.parse(base64_.decode(route.params.obj)):''
      navs.value.style.opacity = 0
      navs.value.style.margin = "500px 0 0 0"
      show_.value = false
      navs.value.style['margin-left'] = 30/128+'rem'
      // get_Navaeticlexq({ type_id: routeData.article_id }) 
      // 如果跳转到文章详情就保存page，否则page改为0
      if(JSON.parse(base64_.decode(route.params.obj)).article_wz_id){}
      else{
        window.sessionStorage.setItem('page',1)
      }
      editFrompage()
      getTablist()
  }, { deep: true})

  // 页面加载就把元素赋值动画初始值
  navs.value.style.opacity = 0
  show_.value = false
  navs.value.style.margin = "500px 0 0 0"
  navs.value.style['margin-left'] = 30 / 128 + 'rem'
  //  get_Navaeticlexq({ type_id: routeData.article_id })
   editFrompage()
   getTablist()
  })
import { ElNotification  } from 'element-plus'
  // 点击li跳转到详情
let pushArticle_xq = (item) => {
  // 如果item.is_direct_down为1就直接下载，不跳转
  if(item.is_direct_down == 1){
    const url_ = item.pic_url[0]
    if(url_){
      window.open(baseUrl+url_.url)
    }else{
    ElNotification({
    title: '提示',
    message: '未上传附件...',
    type: 'warning',
    position: 'top-right',
    zIndex:'99999999999'
  })
  }
    return
  }
  // 判断如通是tab下点击的，就跳转并且带入参数
  if(routeData.i4){
    router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      active:routeData.active,
      article_wz_id:item.id,
      id:routeData.id,
      index:routeData.index,
      level:routeData.level,
      title1:routeData.title1,
      title2:routeData.title2,
      title3:routeData.title3,
      title4:item.type_name
    }))}
  })
  }else{
    router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      article_wz_id:item.id,
      title1:routeData.title1,
      title2:item.type_name,
      id:routeData.id,
      index: routeData.index,
    }))}
  })
  }
}
const tabs = ref(null)
// 获取右侧tab导航数据，判断tab栏是否显示
let getTablist = async () => {
  let from_ = {
        id:routeData.article_id,
        level:routeData.level,                                         
      }
  const {data:res} = await getNavlist_id_f(from_)
         if(from_.level == 3 && res.data[0]){
          show_tab.value = true
          tabListdata.value = res.data
          aindex_(routeData.i4)
          from.type_id = routeData.id4
          get_Navaeticlexq(from)
         }else{
          show_tab.value = false
         }
}
// 点击tab栏请求不同数据
let tabclicks = (item,i) => {
  aindex_(i)
  from.type_id = item.id
  get_Navaeticlexq(from)
}
// 选中高亮tab栏某一个
const aindex_ = (index) => {
           nextTick(() => {
            const tabs_ = tabs.value ? tabs.value.children : []
            if(tabs_){
              for(let i = 0; i < tabs_.length; i ++){
              tabs_[i].className = ''
            }
              tabs_[index].className = 'aindex'
            }
            })
}
// 触发分页事件
const pageChanges = (ee) => {
  from.page = ee
  window.sessionStorage.setItem('page',ee)
  get_Navaeticlexq(from)
}

onUnmounted(() => {
  if(!route.params.obj) return
  if(!JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
     window.sessionStorage.setItem('page',1)
  }
})
</script>

<style lang="less" scoped>
@maxw:128rem;
.article_box_xq{
  width: 100%;
  font-size: 15/@maxw;
  margin: 0 30/@maxw;
  transition: ease .6s;
  h1{
    // text-align: center;
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 20/@maxw 0;
  }
  nav{
     transition: ease .8s;
     margin: 30/@maxw 0 0 0;
     font-family: Source Han Sans CN-Medium;
 
  }

  .share{
    position: absolute;
    right: -166/@maxw;
    top: 300/@maxw;
  }
}

// 动画函数
@keyframes pageYs {
  from{
    transform: translateY(800/@maxw);
    opacity: 0;
  }
  to{
    transform: translateY(0);
    opacity: 1;
  }
}
 .nav_list{
    margin: 50/@maxw 30/@maxw 0 30/@maxw;
    width: 100%;
    >ul{
      margin: 0;
      padding: 0;
      >li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #ccc;
        transition: ease .3s;
        padding: 0 0 0 10/@maxw;
        cursor: pointer;
        // >img{
        //   margin: 0 0 0 10/@maxw;
        //   width: 13/@maxw !important;
        //   height: 38%;
        // }
        .top_top_new{
          width: 40/@maxw;
          height: 45%;
        }
        .top_no_topNew{
          margin: 0 0 0 10/@maxw;
          width: 13/@maxw !important;
          height: 38%;
        }
        >span{
          flex: 1;
          margin: 0 15/@maxw 0 30/@maxw;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #5A5A5A;
        }
        >a{
          margin: 0;
          padding: 0;
          width: 115/@maxw;
          white-space: nowrap;
        }
      }
       
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
  .tabs{
    position: relative;
    margin-top: 35/@maxw;
    width: 100%;
    height: 65/@maxw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: Source Han Sans CN-Medium;
    nav{
      position: relative;
      margin: 0;
      padding: 0;
      flex: 1;
      height: 100%;
      background-color: #B8C9E3;
      font-size: 28/@maxw;
      color: #517DBE;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease .8s;
      
    }
    >nav:nth-child(2n){
      margin: 0 3/@maxw 0 3/@maxw;
    }
    >nav >i{
      position: absolute;
      left: 25/@maxw;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 11/@maxw;
      height: 19/@maxw;
      background-image: url("../../../assets/xiazaizq.png");
      background-size: 100% 100%;
      opacity: 0;
      transition: ease .8s;
    }
    >nav:hover{
    background-color: #517DBE;
    color: #fff !important;
    >i{
      opacity: 1;
    }
    }
  }
  .tabs::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #517DBE;
  }
  .aindex{
    background-color: #517DBE !important;
    color: #fff !important;
    >i{opacity: 1 !important;}
  }
  .pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .article_box_xq{
    margin: 0 !important;
    padding: 0 !important;
    >h1{
      font-size: 35/@maxw_;
    }
  }
  .nav_list{
    margin: 10/@maxw_ 0;
    ul{
      margin: 0 !important;
      font-family: Source Han Sans CN-Medium;
      li{
        height: 100/@maxw_;
         .top_top_new{
          // width: 8/@maxw_;
          width: 8vw !important;
          height: 30%;
        }
        .top_no_topNew{
          margin: 0 2vw;
          // width: 4/@maxw_ !important;
          width: 2vw !important;
          height: 24%;
        }
        >span{
          font-size: 30/@maxw_;
        }
        >a{
          width: 115/@maxw_;
          font-size: 28/@maxw_;
          margin-right: 40/@maxw_;
        }
      }
    }
  }
  .tabs{
    width: 100%;
    height: 65/@maxw_;
    >nav{
    font-size: 30/@maxw_ !important;
    i{
      width: 11/@maxw_;
      height: 19/@maxw_;
    }
    }
  }
  .share{
    position: absolute;
    right: -166/@maxw_;
    top: 300/@maxw_;
    display: none;
  }
}
</style>  